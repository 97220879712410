import React from "react";
import "./App.css";
function Eventos() {
  return (
    <div>
      {/* ? Header  */}
      <div class="row">
        <header class="header">
          <nav class="navbar navbar-expand-lg py-3 fixed-top">
            <div class="container">
              <div class="d-flex justify-content-center align-items-center">
                <a
                  class="nav-logo m-0 mx-0 me-lg-3 p-0 text-decoration-none me-md-3"
                  href="#">
                  HipHop
                </a>
                <img
                  src="./images/svg/cd.svg"
                  alt="cd"
                  id="cd"
                  class="m-0 px-0 d-none d-lg-block d-md-block d-sm-block"
                />
              </div>

              <button
                class=" navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon rounded rounded-1 "></span>
              </button>

              <div class="collapse navbar-collapse me-lg-3" id="navbarNav">
                <ul class="nav-ul navbar-nav mx-auto me-0 pe-3 align-items-md-center align-items-center">
                  <li class="mt-md-4 mt-sm-3 mt-4 m-lg-0 nav-item">
                    <a
                      class="px-lg-4 px-md-5 px-sm-5 px-5 nav-link"
                      aria-current="page"
                      href="/home">
                      Inicio
                    </a>
                  </li>
                  <li class="mt-md-3 mt-sm-3 mt-3 m-lg-0 nav-item">
                    <a
                      class="px-lg-4 px-md-5 px-sm-5 px-5 nav-link"
                      aria-current="page"
                      href="/videos">
                      Video clips
                    </a>
                  </li>
                  <li class="mt-md-3 mt-sm-3 mt-3 m-lg-0 nav-item">
                    <a
                      class="px-lg-4 px-md-5 px-sm-5 px-5 nav-link"
                      aria-current="page"
                      href="/demos">
                      Demos
                    </a>
                  </li>
                  <li class="mt-md-3 mt-sm-3 mt-3 m-lg-0 nav-item">
                    <a
                      class="px-lg-4 px-md-5 px-sm-5 px-5 nav-link"
                      aria-current="page"
                      href="/disc">
                      Discografia
                    </a>
                  </li>
                  <li class="mt-md-3 mt-sm-3 mt-3 m-lg-0 nav-item">
                    <a
                      class="px-lg-4 px-md-5 px-sm-5 px-5 nav-link"
                      aria-current="page"
                      href="/bio">
                      Biografia
                    </a>
                  </li>
                  <li class="mt-md-3 mt-sm-3 mt-3 m-lg-0 mt-sm-3 nav-item">
                    <a
                      class="px-lg-4 px-md-5 px-sm-5 px-5 nav-link"
                      aria-current="page"
                      href="/eventos">
                      Eventos
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </div>

      {/* ? Eventos  */}

      <div class="container-events vh-100">
        <article class="events mt-5 p-3">
          <div class="event-top mt-lg-5 text-center">
            <h2 class="event-title">Medellín</h2>
            <h3 class="event-data">16/Jul/2023</h3>
          </div>

          <div class="event-bottom">
            <div class="event-img bg-black">
              <img src="./images/jpg/event.jpg" alt="" />
            </div>

            <div class="event-info">
              <p>
                {" "}
                <span id="event-info_title">Turknao y OG Care-k</span>
              </p>
              <p>
                Fecha: <span>16 JULIO 2023</span>
              </p>
              <p>
                Ciudad: <span>Medellín</span>
              </p>
              <p>
                Fecha: <span>Santa Kata</span>
              </p>

              <div class="event-botons">
                <a id="buy" href="#">
                  Comprar tickets
                </a>
                <a id="com" href="#">
                  <img src="./images/svg/compartir.svg" alt="Compartir" />
                  Compartir
                </a>
              </div>
            </div>
          </div>
        </article>
      </div>

      {/* ? Footer  */}

      <footer class="footer">
        <div class="container d-flex flex-column align-items-center p-3 pb-0">
          <h1 class="footer-title text-center">
            Escúchanos en <br /> <span class="">Plataformas</span>
          </h1>

          <div class="footer-imgs d-flex justify-content-around w-75 mt-4">
            <a href="#">
              <img
                src="./images/svg/youtube-f-w.svg"
                alt="YouTube"
                width="170"
                title="YouTube"
                // style="margin-top: -40px; margin-right: -30px;"
                id="img-yt"
              />
            </a>
            <a href="#">
              <img
                src="./images/svg/yt_music-f-w.svg"
                alt="YouTube Music"
                width="80"
                title="YouTube Music"
              />
            </a>
            <a href="#">
              <img
                src="./images/svg/spotify-f-w.svg"
                alt="Spotify"
                width="80"
                title="Spotify"
              />
            </a>
            <a href="#">
              <img
                src="./images/svg/deezer-f-w.svg"
                alt="Dezzer"
                width="80"
                title="Deezer"
              />
            </a>
          </div>

          <div class="comunication  w-50 d-lg-flex justify-content-around align-items-center py-3 pb-5 d-grid col-1 ">
            <div class="footer-contact text-center">
              <h2>Contacto</h2>
              <p>care-k@hotmail.com</p>
              <p>3008251145</p>
            </div>

            <div class="footer-bussines text-center">
              <h2>Contrataciones</h2>
              <p>care-k@hotmail.com</p>
              <p>3008251145</p>
            </div>
          </div>
        </div>

        <div class="copy d-flex justify-content-between mx-3 fs-6">
          <p>2023, &copy; TURKANO | OG CARE-K</p>
          <p class="otto text-uppercase">&copy; OTTO | Carlos Arteaga</p>
        </div>
      </footer>
    </div>
  );
}

export default Eventos;
