import React from "react";
import "./App.css";

function Bio() {
  return (
    <div>
      {/* ? Header */}
      <div className="row">
        <header className="header">
          <nav className="navbar navbar-expand-lg py-3 fixed-top">
            <div className="container">
              <div className="d-flex justify-content-center align-items-center">
                <a
                  className="nav-logo m-0 mx-0 me-lg-3 p-0 text-decoration-none me-md-3"
                  href="#">
                  HipHop
                </a>
                <img
                  src="./images/svg/cd.svg"
                  alt="cd"
                  id="cd"
                  className="m-0 px-0 d-none d-lg-block d-md-block d-sm-block"
                />
              </div>

              <button
                className=" navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon rounded rounded-1 "></span>
              </button>

              <div className="collapse navbar-collapse me-lg-3" id="navbarNav">
                <ul className="nav-ul navbar-nav mx-auto me-0 pe-3 align-items-md-center align-items-center">
                  <li className="mt-md-4 mt-sm-3 mt-4 m-lg-0 nav-item">
                    <a
                      className="px-lg-4 px-md-5 px-sm-5 px-5 nav-link"
                      aria-current="page"
                      href="/home">
                      Inicio
                    </a>
                  </li>
                  <li className="mt-md-3 mt-sm-3 mt-3 m-lg-0 nav-item">
                    <a
                      className="px-lg-4 px-md-5 px-sm-5 px-5 nav-link"
                      aria-current="page"
                      href="/videos">
                      Video clips
                    </a>
                  </li>
                  <li className="mt-md-3 mt-sm-3 mt-3 m-lg-0 nav-item">
                    <a
                      className="px-lg-4 px-md-5 px-sm-5 px-5 nav-link"
                      aria-current="page"
                      href="/demos">
                      Demos
                    </a>
                  </li>
                  <li className="mt-md-3 mt-sm-3 mt-3 m-lg-0 nav-item">
                    <a
                      className="px-lg-4 px-md-5 px-sm-5 px-5 nav-link"
                      aria-current="page"
                      href="/disc">
                      Discografia
                    </a>
                  </li>
                  <li className="mt-md-3 mt-sm-3 mt-3 m-lg-0 nav-item">
                    <a
                      className="px-lg-4 px-md-5 px-sm-5 px-5 nav-link"
                      aria-current="page"
                      href="/bio">
                      Biografia
                    </a>
                  </li>
                  <li className="mt-md-3 mt-sm-3 mt-3 m-lg-0 mt-sm-3 nav-item">
                    <a
                      className="px-lg-4 px-md-5 px-sm-5 px-5 nav-link"
                      aria-current="page"
                      href="/eventos">
                      Eventos
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </div>

      {/* ? Contenido */}

      <div className="bio container-lg container-md">
        <div className="group">
          <div className="bio-title_group text-center pt-lg-4 pt-md-4 pt-sm-4 pt-1">
            <h2>Bio Grupo</h2>
          </div>
          <div className="bio-text-group d-flex justify-content-center">
            <p className="w-100">
              OG Care-k y Turkano se conocen en el año 2022 a través de socios
              comunes apasionados por el G-funk y el estilo de vida de
              California, al primer contacto se escribe la canción titulada Soy
              una droga y semanas después ambos se dirigen al estudio de
              grabación a rapear dicha canción, ya que Turkano creció en Urabá
              es OG-Carek quién consigue los primeros toques en duo en la ciudad
              de Medellín, siendo el primer Toque en el Barrio Antioquía y
              posterior a ese evento se escriben más canciones y ambos empienzan
              a hacerse notar en la escena Hip Hop de la ciudad de Medellín.
            </p>
          </div>

          <div className="bio-img_group"></div>
        </div>

        <div className="mena container-lg container-md mt-lg-5 py-lg-4 mt-md-1 py-md-4 mt-sm-1 py-sm-3  mt-2 py-3 ">
          <div className="right">
            <h2 className="bio-title_mena text-center">Turkano</h2>
            <h4 className="bio-data_mena text-start">19-1-17</h4>
            <p className="bio-text_mena">
              Su nombre de nacimiento es Jonathan Alexander Mena Salcedo y nació
              el 12 de noviembre de 1991 en la Ciudad de Medellín, creció en el
              municipio de Chigorodó y actualmente vive en la ciudad de
              Medellín, su nombre artístico es Turkano nombre que proviene de la
              tribu Africana Turkana ya que Turkano representa todas las
              manifestaciones Afro, Turkano es un intérprete de rap y conoció
              este estilo de música cuando era un niño de 12 años tomando
              influencias de artistas como Vico C, 2pac, Snoop Dogg y Eminem, a
              medida que fue pasando el tiempo la trayectoria de Turkano fue
              tomando forma y sus estilo se influenció principalmente por el
              estilo de California en dónde los ritmos de G-funk y el sonido de
              la costa oeste hacen parte de su catálogo musical. Turkano además
              de tener una ingeniosa capacidad lirica al escribir también
              disfruta del arte del Freestyle y es reconocido a nivel local con
              canciones como Mulata, Microphone killa y Get the money.
            </p>
          </div>

          <div className="bio-img_mena left ms-lg-3">
            <img
              src="./images/jpg/bio-mena.jpg"
              alt="Foto de Mena"
              className="d-none d-sm-none d-md-none d-lg-block"
            />
          </div>
        </div>

        <div className="og container-lg container-md mt-lg-5 py-lg-4 mt-md-1 py-md-4 mt-sm-1 py-sm-3 mt-1 pt-1 pb-4">
          <div className="bio-img_og right me-lg-3">
            <img
              src="./images/jpg/bio-og.jpg"
              alt="Foto de Og"
              className="d-none d-sm-none d-md-none d-lg-block"
            />
          </div>

          <div className="left">
            <h2 className="bio-title_og text-center">OG Care-k</h2>
            <h4 className="bio-data_mena text-start">03-01-91</h4>
            <p className="bio-text_og">
              Nacido el 03 de enero de 1991 en la ciudad de Medellín (Ant.), fue
              nombrado Carlos Tellez Posada. Criado por sus padres entre el
              corregimiento de San Antonio de Prado (Medellín) y el municipio de
              Itagüí, asentando su familia y allegados mayormente en este último
              lugar. Fue apodado de varias maneras a lo largo de su vida, pero
              principalmente se le conoce por OG Care-k, sobrenombres que le
              fueron asignados por amistades desde la infancia. El apodo de "OG"
              fue asignado por amistades de su infancia en San Antonio de Prado,
              en el año 1999, tiempo en el que a su hermano menor (Fernando) y a
              él los llamaban OG's por ciertas actividades underground que
              realizaban. El apodo de "Care-k" le fue asignado en el año 2005
              cuando ingresó a la escuela de Capoeira "Dois Mares", pues para
              ese año se hacía cortes de cabello muy bajos, por lo que en su
              familia de capoeira le asignaron el sobrenombre (apelido) de
              "Calvo", que se escribe en portugués "Careca", simplemente le
              cambió la forma de escribirse, personalizando su apodo. Es así
              como en adelante se le conoció en todo lugar como OG CARE-K. La
              última pieza del sobrenombre artístico fue "Xtyle", sobrenombre
              adicional que en el año 2011 se asignaron las tres (3) personas
              que conformaron la agrupación musical Itagüiseña "Absolute Xtyle"
              entre las cuales Care-k fue fundador, por lo que en adelante se le
              conoció en el mundo artístico como "OG Care-k Xtyle". OG Care-k es
              un artista musical compositor e interprete de Rap, especialmente
              del estilo G-funk. Su pasión por la cultura del Rap/G-funk
              ₩estCoast fue influenciada desde finales de los años 90's por un
              amigo proveniente de Long Beach California, por lo que su estilo
              de vida Hip Hop lo acomodó desde temprana edad a la cultura
              musical de la Costa Oeste Estadounidense, adquiriendo conocimiento
              sobre todo lo que implicaba la cultura de aquel lugar. En el
              aspecto musical, estuvo mayormente influenciado por la música Rap
              del área de la bahía, del condado de Los Angeles, del denominado
              Midwest y el Southern GangstaRap, todos estos estilos de Rap
              Norteamericano. Se caracteriza por tener una gran capacidad de
              escritura al momento de componer sus canciones, siendo ágil al
              ingeniar sus líricas. Se le conoce en la escena del Rap local del
              Valle de Aburrá, por ser el Rapero que expone siempre el estilo
              G-funk West Coast en el área. Ha participado en numerosos eventos
              intermunicipales en Antioquia y en numerosas discotecas y bares,
              así como en muchos festivales culturales de los municipios del
              Valle de Aburrá y ha co-participado de festividades desarrolladas
              por diversos conglomerados artísticos del sur del Valle de Aburrá.
              Actualmente se dedica al proyecto musical de Rap G-funk con
              Turkano.
            </p>
          </div>
        </div>
      </div>

      {/* ? Pie  */}

      <footer className="footer">
        <div className="container d-flex flex-column align-items-center p-3 pb-0">
          <h1 className="footer-title text-center">
            Escúchanos en <br /> <span className="">Plataformas</span>
          </h1>

          <div className="footer-imgs d-flex justify-content-around w-75 mt-4">
            <a href="#">
              <img
                src="./images/svg/youtube-f-w.svg"
                alt="YouTube"
                width="170"
                title="YouTube"
                // style="margin-top: -40px; margin-right: -30px;"
                id="img-yt"
              />
            </a>
            <a href="#">
              <img
                src="./images/svg/yt_music-f-w.svg"
                alt="YouTube Music"
                width="80"
                title="YouTube Music"
              />
            </a>
            <a href="#">
              <img
                src="./images/svg/spotify-f-w.svg"
                alt="Spotify"
                width="80"
                title="Spotify"
              />
            </a>
            <a href="#">
              <img
                src="./images/svg/deezer-f-w.svg"
                alt="Dezzer"
                width="80"
                title="Deezer"
              />
            </a>
          </div>

          <div className="comunication  w-50 d-lg-flex justify-content-around align-items-center py-3 pb-5 d-grid col-1 ">
            <div className="footer-contact text-center">
              <h2>Contacto</h2>
              <p>care-k@hotmail.com</p>
              <p>3008251145</p>
            </div>

            <div className="footer-bussines text-center">
              <h2>Contrataciones</h2>
              <p>care-k@hotmail.com</p>
              <p>3008251145</p>
            </div>
          </div>
        </div>

        <div className="copy d-flex justify-content-between mx-3 fs-6">
          <p>2023, &copy; TURKANO | OG CARE-K</p>
          <p className="otto text-uppercase">&copy; OTTO | Carlos Arteaga</p>
        </div>
      </footer>
    </div>
  );
}

export default Bio;
