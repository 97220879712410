import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./home";
import Bio from "./bio";
import Videos from "./videos";
import Demos from "./demos";
import Eventos from "./eventos";
import Discografia from "./discografia";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/bio" element={<Bio />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/demos" element={<Demos />} />
          <Route path="/eventos" element={<Eventos />} />
          <Route path="/disc" element={<Discografia />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
